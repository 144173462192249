export class MissingDOMError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "DomError";
  }
}

export class ApiError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ApiError";
  }
}

export class TypeableSelectError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "TypeableSelectError";
  }
}

export const stringifyError = (error: unknown): string => {
  if (error instanceof Error) return error.message;
  if (typeof error === "object") return JSON.stringify(error);
  return String(error);
};
