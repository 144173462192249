import { noNullQuerySelector } from "../src/utils/dom";

document.addEventListener("DOMContentLoaded", () => {
  const dropdown = noNullQuerySelector<HTMLAnchorElement>(
    "#subject-and-courses-link",
  );
  const menu = noNullQuerySelector("#subject-and-courses-dropdown");

  // Hover behavior to open the dropdown
  dropdown.addEventListener("mouseenter", () => {
    openDropdown();
  });

  menu.addEventListener("mouseenter", () => {
    openDropdown();
  });

  // Hover behavior to close the dropdown
  dropdown.addEventListener("mouseleave", () => {
    setTimeout(() => {
      if (!menu.matches(":hover")) closeDropdown();
    }, 200); // Slight delay for smoother transitions
  });

  menu.addEventListener("mouseleave", () => {
    setTimeout(() => {
      if (!dropdown.matches(":hover")) closeDropdown();
    }, 200);
  });

  // Click behavior to open the link in a new tab
  dropdown.addEventListener("click", (event) => {
    event.preventDefault(); // Prevent default dropdown behavior
    const link = dropdown.getAttribute("href");
    if (link) {
      window.open(link, "_blank"); // Open link in a new tab
    }
  });

  function openDropdown() {
    dropdown.setAttribute("aria-expanded", "true");
    menu.classList.add("show");
  }

  function closeDropdown() {
    dropdown.setAttribute("aria-expanded", "false");
    menu.classList.remove("show");
  }
});
