import { MissingDOMError } from "./error";

/**
 * Finds the first element that matches the specified selector within the given parent element.
 * Throws an error if no element is found.
 *
 * @template T - The type of the element to be returned. Defaults to HTMLElement.
 * @param {string} selector - The CSS selector to match the element.
 * @param {Document | Element} parent - The parent element to search within. Defaults to document.
 * @returns {T} - The first element that matches the selector.
 * @throws {MissingDOMError} - If no element is found with the specified selector.
 */
export const noNullQuerySelector = <T extends Element = HTMLElement>(
  selector: string,
  parent: Document | Element = document,
): T => {
  const element = parent.querySelector<T>(selector);
  if (!element) {
    throw new MissingDOMError(`Element with selector '${selector}' not found`);
  }
  return element;
};
